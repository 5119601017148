export const filterMenuItems = (menuItems, userPlan) => {
  return menuItems.filter((item) => {
    if (item.key === "3" || item.key === "5") {
      // Allow SAP AI Consultant and Logout for all plans
      return true;
    }

    if (item.key === "4" && (userPlan === "Tier 2" || userPlan === "Tier 3")) {
      // Allow Co-Pilots only for T2 and T3 plans
      return true;
    }

    // Exclude all other items
    return false;
  });
};

// Define the plans and their hierarchy
const planHierarchy = ["Free", "Tier 1", "Tier 2", "Tier 3"];

// Function to get allowed routes based on user plan
export const getAllowedRoutes = (userPlan, data) => {
  if (!planHierarchy.includes(userPlan)) {
    // If the userPlan is not in the hierarchy, use its specific allowed routes
    return [...new Set(data[userPlan]?.allowed_routes || [])];
  }

  // For plans in the hierarchy, compute allowed routes based on hierarchy
  const allowedPlans = planHierarchy.slice(
    0,
    planHierarchy.indexOf(userPlan) + 1
  );
  const allowedRoutes = allowedPlans
    .map((plan) => data[plan]?.allowed_routes || [])
    .flat();
  return [...new Set(allowedRoutes)]; // Remove duplicates
};


export const filterCustomUserMenuItems = (menuItems, activePlanRoute) => {
  return menuItems.filter((item) => {
    if (item.key === "5") {
      // Allow SAP AI Consultant and Logout for all plans
      return true;
    }

    if (item.key === "3" && (activePlanRoute.includes("/chat_with_ai"))) {
      // Allow Co-Pilots only for T2 and T3 plans
      return true;
    }

    if (item.key === "4" && (activePlanRoute.includes("/get_rfp_response") && activePlanRoute.includes("/get_rom_response") && activePlanRoute.includes("/get_sow_response"))) {
      // Allow Co-Pilots only for T2 and T3 plans
      return true;
    }

    // Exclude all other items
    return false;
  });
}