import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LogoutOutlined,
  RobotOutlined,
  TeamOutlined,
  WechatWorkOutlined,
  CrownOutlined,
  BarChartOutlined,
  UserAddOutlined,
  SolutionOutlined,
  CalculatorOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FileProtectOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Typography, Button, Modal, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import logo from "../../assets/images/Eerly_logo_white-transparent.png";
import favicon from "../../assets/images/Eerly_logo_white-transparent.png";
import TicketModal from "../TicketModal/TicketModal";
import PricingTableModal from "../PricingTableModal/PricingTableModal";
import "./Sidebar.css";
import { filterCustomUserMenuItems, filterMenuItems, getAllowedRoutes } from "./mockData";
import { AppContext } from "../../context/AppContext";

const { Sider } = Layout;

const { Title } = Typography;

const Sidebar = ({ collapsed, setCollapsed }) => {
  const auth = useAuth();
  const { activePlanState, updateActivePlanState } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isInsightsModalOpen, setIsInsightsModalOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [customPlan, setCustomPlan] = useState(false);
  const [activePlanRoute, setActivePlanRoute] = useState(null);

  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails && JSON.parse(userDetails);

  console.log(user, "user frm sidebar");
  console.log(activePlanState, 'activePlanState')

  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plans/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${auth.user["access_token"]}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data, "DATA FRM PLAN"); // handle the response data here
        setPlanData(data)
        return data; // Return the data here
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        return null; // Optionally return null or an error object
      }
    };

    fetchPlanData();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsInsightsModalOpen(false);
  };

  const handleCancel = () => {
    setIsInsightsModalOpen(false);
  };

  const getSelectedKey = (pathname) => {
    // Map of pathnames to menu item keys
    const keyMap = {
      // '/overview': '1',
      // '/chat-ai': '2',
      "/chat-with-data": "3",
      "/work-order-copilot": "4-3",
      "/rom-copilot": "4-2",
      "/rfp-copilot": "4-1",
      "/eerly-insights": "6",
      "/policy-assistant": "11",
    };

    // Return the corresponding key or a default value
    return keyMap[pathname] || "3";
  };

  const handleLogout = () => {
    auth.logout();
    navigate("/login"); // Navigate to login page after logout
  };

  // Define the menu items with icons and TypeScript typings
  const emailList = [
    "nilesh.shah@accely.com",
    "sonu.kumar@accely.com",
    "vikkesh.bhatt@accely.com",
    "dhruv.s@accely.com",
    "tahir.ahmed@accely.com",
  ]; // replace with your actual emails

  const newEmaiList = [
    "ahmed.marie@zamilts.com",
    "mai.hassan-k@zamilts.sa",
    "presales.is-k@zamilts.sa",
  ];

  // let menuItems = [
  //   {
  //     key: "3",
  //     icon: <WechatWorkOutlined />,
  //     label: <Link to="/eerly-brain">SAP AI Consultant</Link>,
  //   },
  //   // { key: '11', icon: <FileProtectOutlined />, label: <Link to="https://eerly-policy-bot.onrender.com/chatbot/e121e110-5728-4041-b09f-75a2b17a6040" target="_blank">Policy Assistant</Link> },
  //   {
  //     key: "11",
  //     icon: <FileProtectOutlined />,
  //     label: <Link to="/policy-assistant">Policy Assistant</Link>,
  //   },
  //   {
  //     key: "6",
  //     icon: <BarChartOutlined />,
  //     label: (
  //       <Link
  //         to="/eerly-insights"
  //         //onClick={(e) => { e.preventDefault(); setIsInsightsModalOpen(true); }}
  //       >
  //         Eerly Insights
  //       </Link>
  //     ),
  //   },
  //   {
  //     key: "4",
  //     icon: <SolutionOutlined />,
  //     label: "Co-Pilots",
  //     disabled: user.plan === "Free" && user.role !== "admin",
  //     children: [
  //       {
  //         key: "4-1",
  //         label: (
  //           <Tooltip placement="rightTop" title={"Currently in Beta Mode"}>
  //             <Link to="/rfp-copilot">RFP Copilot</Link>
  //           </Tooltip>
  //         ),
  //         disabled: user.plan === "Free" && user.role !== "admin",
  //       },
  //       {
  //         key: "4-2",
  //         label: (
  //           <Tooltip placement="rightTop" title={"Currently in Beta Mode"}>
  //             <Link to="/rom-copilot">ROM Copilot</Link>
  //           </Tooltip>
  //         ),
  //         disabled: user.plan === "Free" && user.role !== "admin",
  //       },
  //       {
  //         key: "4-3",
  //         label: (
  //           <Tooltip placement="rightTop" title={"Currently in Beta Mode"}>
  //             <Link to="/work-order-copilot">Work Order Copilot</Link>
  //           </Tooltip>
  //         ),
  //         disabled: user.plan === "Free" && user.role !== "admin",
  //       },
  //     ],
  //   },
  // ];

  // // Conditionally add items for users in `newEmailList`
  // if (newEmaiList.includes(user.official_email)) {
  //   menuItems = menuItems.concat([
  //     {
  //       key: "7",
  //       icon: <UserAddOutlined />,
  //       label: (
  //         <Link
  //           to="http://eerlybrain.eastus.cloudapp.azure.com:3000/"
  //           target="_blank"
  //         >
  //           Recruitment AI
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "12",
  //       icon: <FileSearchOutlined />,
  //       label: (
  //         <Link to="https://app-assess.pmapstest.com/login" target="_blank">
  //           Assessment AI
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "10",
  //       icon: <FileTextOutlined />,
  //       label: (
  //         <Link
  //           to="http://eerlybrain.eastus.cloudapp.azure.com:3002/"
  //           target="_blank"
  //         >
  //           Use Case Generator
  //         </Link>
  //       ),
  //     },
  //   ]);
  // }

  // // Conditionally add more items for users in `emailList`
  // if (emailList.includes(user.official_email)) {
  //   menuItems = menuItems.concat([
  //     {
  //       key: "7",
  //       icon: <UserAddOutlined />,
  //       label: (
  //         <Link
  //           to="http://eerlybrain.eastus.cloudapp.azure.com:3000/"
  //           target="_blank"
  //         >
  //           Recruitment AI
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "12",
  //       icon: <FileSearchOutlined />,
  //       label: (
  //         <Link to="https://app-assess.pmapstest.com/login" target="_blank">
  //           Assessment AI
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "8",
  //       icon: <RobotOutlined />,
  //       label: (
  //         <Link to="http://65.2.174.174:41020/" target="_blank">
  //           SF AI Bot
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "9",
  //       icon: <CalculatorOutlined />,
  //       label: (
  //         <Link
  //           to="http://eerlybrain.eastus.cloudapp.azure.com:8502/"
  //           target="_blank"
  //         >
  //           Eerly Cost Calculator
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "10",
  //       icon: <FileTextOutlined />,
  //       label: (
  //         <Link
  //           to="http://eerlybrain.eastus.cloudapp.azure.com:3002/"
  //           target="_blank"
  //         >
  //           Use Case Generator
  //         </Link>
  //       ),
  //     },
  //   ]);
  // }

  // menuItems = menuItems.concat([
  //   {
  //     key: "13",
  //     icon: <SyncOutlined />,
  //     label: (
  //       <Link to="https://rostering.netlify.app/dashboard" target="_blank">
  //         Rostering AI
  //       </Link>
  //     ),
  //   },
  //   {
  //     key: "5",
  //     icon: <LogoutOutlined />,
  //     label: (
  //       <Link to="" onClick={handleLogout}>
  //         Logout
  //       </Link>
  //     ),
  //   },
  // ]);

  const allMenuItems = [
    {
      key: "3",
      icon: <WechatWorkOutlined />,
      label: <Link to="/eerly-brain">SAP AI Consultant</Link>,
    },
    {
      key: "11",
      icon: <FileProtectOutlined />,
      label: <Link to="/policy-assistant">Policy Assistant</Link>,
    },
    {
      key: "6",
      icon: <BarChartOutlined />,
      label: (
        <Link
          to="/eerly-insights"
          //onClick={(e) => { e.preventDefault(); setIsInsightsModalOpen(true); }}
        >
          Eerly Insights
        </Link>
      ),
    },
    {
      key: "4",
      icon: <SolutionOutlined />,
      label: "Co-Pilots",
      disabled: user.plan === "Free" && user.role !== "admin",
      children: [
        {
          key: "4-1",
          label: (
            <Tooltip placement="rightTop" title={"Currently in Beta Mode"}>
              <Link to="/rfp-copilot">RFP Copilot</Link>
            </Tooltip>
          ),
          disabled: user.plan === "Free" && user.role !== "admin",
        },
        {
          key: "4-2",
          label: (
            <Tooltip placement="rightTop" title={"Currently in Beta Mode"}>
              <Link to="/rom-copilot">ROM Copilot</Link>
            </Tooltip>
          ),
          disabled: user.plan === "Free" && user.role !== "admin",
        },
        {
          key: "4-3",
          label: (
            <Tooltip placement="rightTop" title={"Currently in Beta Mode"}>
              <Link to="/work-order-copilot">Work Order Copilot</Link>
            </Tooltip>
          ),
          disabled: user.plan === "Free" && user.role !== "admin",
        },
      ],
    },
    {
      key: "7",
      icon: <UserAddOutlined />,
      label: (
        <Link
          to="http://eerlybrain.eastus.cloudapp.azure.com:3000/"
          target="_blank"
        >
          Recruitment AI
        </Link>
      ),
    },
    {
      key: "12",
      icon: <FileSearchOutlined />,
      label: (
        <Link to="https://app-assess.pmapstest.com/login" target="_blank">
          Assessment AI
        </Link>
      ),
    },
    {
      key: "8",
      icon: <RobotOutlined />,
      label: (
        <Link to="http://65.2.174.174:41020/" target="_blank">
          SF AI Bot
        </Link>
      ),
    },
    {
      key: "9",
      icon: <CalculatorOutlined />,
      label: (
        <Link
          to="http://eerlybrain.eastus.cloudapp.azure.com:8502/"
          target="_blank"
        >
          Eerly Cost Calculator
        </Link>
      ),
    },
    {
      key: "10",
      icon: <FileTextOutlined />,
      label: (
        <Link
          to="http://eerlybrain.eastus.cloudapp.azure.com:3002/"
          target="_blank"
        >
          Use Case Generator
        </Link>
      ),
    },
    {
      key: "13",
      icon: <SyncOutlined />,
      label: (
        <Link to="https://rostering.netlify.app/dashboard" target="_blank">
          Rostering AI
        </Link>
      ),
    },
    {
      key: "5",
      icon: <LogoutOutlined />,
      label: (
        <Link to="" onClick={handleLogout}>
          Logout
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (user?.plan && menuItems?.length === 0 && activePlanRoute !== null) {
      if (user?.role === "user" && (user?.plan === "Free" ||  user?.plan === "Tier 1" || user?.plan === "Tier 2" || user?.plan ===  "Tier 3")) {
        setMenuItems(filterMenuItems(allMenuItems, user?.plan));
      } 
      else if (user?.role === "user" && (user?.plan !== "Free" ||  user?.plan !== "Tier 1" || user?.plan !== "Tier 2" || user?.plan !==  "Tier 3")) {
        setMenuItems(filterCustomUserMenuItems(allMenuItems, activePlanRoute));
      }
      else {
        setMenuItems(allMenuItems);
      }
    }
  }, [user, activePlanRoute]);

  useEffect(() => {
    if (user?.plan && planData !== null && activePlanRoute === null) {
      setActivePlanRoute(getAllowedRoutes(user?.plan, planData));
      updateActivePlanState('data', getAllowedRoutes(user?.plan, planData))
    }
  }, [user, planData]);

  console.log(activePlanRoute, 'activePlanRoute')
  console.log(planData)

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        className="sidebar-main"
        width={210}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          backgroundColor: "#000000",
        }}
      >
        {collapsed ? (
          <div style={{ textAlign: "center" }}>
            <img
              src={favicon}
              alt=""
              style={{ margin: "20px 10px", width: "50px", height: "auto" }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "20px 10px" }}>
            <img src={logo} alt="" width="80%" />
          </div>
        )}
        <Menu
          theme="dark"
          defaultSelectedKeys={[getSelectedKey(location.pathname)]}
          mode="inline"
          items={menuItems}
          style={{ backgroundColor: "#000000" }}
        />
        {collapsed ? (
          <Button
            type="default"
            onClick={showModal}
            style={{
              position: "absolute",
              bottom: 60,
              width: "80%",
              color: "#0070f2",
              cursor: "pointer",
              backgroundColor: "transparent",
              borderColor: "#0070f2",
              marginLeft: "10px",
            }}
          >
            <TeamOutlined />
          </Button>
        ) : (
          <Button
            type="default"
            onClick={showModal}
            style={{
              position: "absolute",
              bottom: 60,
              width: "90%",
              color: "#0070f2",
              cursor: "pointer",
              backgroundColor: "transparent",
              borderColor: "#0070f2",
              marginLeft: "10px",
            }}
          >
            <TeamOutlined /> Support
          </Button>
        )}
        {collapsed ? (
          <>
            <Button
              type="default"
              onClick={() => setIsUpgradeModalOpen(true)}
              style={{
                position: "absolute",
                bottom: 120,
                width: "80%",
                color: "#ffd800",
                cursor: "pointer",
                backgroundColor: "transparent",
                borderColor: "#ffd800",
                marginLeft: "10px",
              }}
            >
              <CrownOutlined />
            </Button>
          </>
        ) : (
          <>
            <Button
              type="default"
              onClick={() => setIsUpgradeModalOpen(true)}
              style={{
                position: "absolute",
                bottom: 120,
                width: "90%",
                color: "#ffd800",
                cursor: "pointer",
                backgroundColor: "transparent",
                borderColor: "#ffd800",
                marginLeft: "10px",
              }}
            >
              <CrownOutlined /> Upgrade Plan
            </Button>
          </>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 20,
            width: "100%",
            textAlign: "center",
          }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <DoubleRightOutlined style={{ color: "#fff", cursor: "pointer" }} />
          ) : (
            <DoubleLeftOutlined
              style={{ color: "#fff", zIndex: 1, cursor: "pointer" }}
            />
          )}
        </div>
      </Sider>
      <TicketModal
        isModalVisible={isModalOpen}
        setIsModalVisible={setIsModalOpen}
      />
      <PricingTableModal
        visible={isUpgradeModalOpen}
        setVisible={setIsUpgradeModalOpen}
      />
      <Modal
        title="Eerly Insights"
        open={isInsightsModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
        keyboard={false}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={3} style={{ padding: "50px 10px" }}>
            Coming Soon...
          </Title>
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;
